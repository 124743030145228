


















import { Vue, Component } from 'vue-property-decorator'
import { getError } from '@/utils/helpers'
import AuthService from '@/services/AuthService'

@Component
export default class VerifyEmail extends Vue {
    email = '';
    password = '';
    passwordConfirm = '';
    error = '';
    message = '';

    async sendVerification ()
    {
        this.error = ''
        this.message = ''
        const authUser = await this.$store.dispatch('auth/getAuthUser')
        const payload = {
            user: authUser.id
        }
        AuthService.sendVerification(payload)
            .then((response) => (this.message = response.data.message))
            .catch((error) => (this.error = getError(error)))
    }
}
